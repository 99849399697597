<div class="container">
    <div class="row">
        <div class="col-xl-12 main-title">
            <h4>Life Story Telling</h4>
        </div>
        <div class="col-xl-12">
            <div class="row">
                <div class="col-xl-12 text-right">
                  <button data-toggle="tooltip" data-placement="bottom" title="Export to image" type="button" class="btn btn-primary m-1" (click)="rasterize();">
                      <i class="fa fa-download" aria-hidden="true"></i> Export to image</button>
                  <!--<button data-toggle="tooltip" data-placement="bottom" title="Export to SVG" type="button" class="btn btn-primary m-1" (click)="rasterizeSVG();">
                      <i class="fa fa-download" aria-hidden="true"></i> Export to SVG</button>-->
                  <button data-toggle="tooltip" data-placement="bottom" title="Save in localStorage" type="button" class="btn btn-primary m-1"
                      (click)="saveCanvasToJSON();">
                      <i class="fa fa-save" aria-hidden="true"></i> Save local</button>
                  <!--<button data-toggle="tooltip" data-placement="bottom" title="Load from localStorage" type="button" class="btn btn-primary m-1"
                      (click)="loadCanvasFromJSON();">
                      <i class="fa fa-save" aria-hidden="true"></i> Load local</button>-->
                  <button data-toggle="tooltip" data-placement="bottom" title="Load from localStorage" type="button" class="btn btn-primary m-1"
                      (click)="loadCanvasFromJSON();">
                    <i class="fa fa-save" aria-hidden="true"></i> Load local</button>
                  
                  <!--<input id="selectFiles" type="file" (change)="readUrl($event);">-->
                  <input id="selectFiles" type="file">
                  <button id="import" data-toggle="tooltip" data-placement="bottom" title="Load from file" type="button" class="btn btn-primary m-1"
                      (click)="loadCanvasFromJSONFile();">
                      <i class="fa fa-save" aria-hidden="true"></i> Load File</button>                      
                
                  <button data-toggle="tooltip" data-placement="bottom" title="Clean Canvas" type="button" class="btn btn-danger" (click)="confirmClear();">
                      <i class="fa fa-ban" aria-hidden="true"></i> Clean</button>
              </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-xl-3">
            <!--<div class="card">
              <div class="card-header">Size</div>
              <div class="card-body">
                  <div class="form-group">
                      <div class="input-group">
                          <input type="text" class="form-control" [(ngModel)]="canvas.size.width" (keyup)="changeSize()">
                          <div class="input-group-append">
                              <span class="input-group-text">
                                  Width
                              </span>
                          </div>
                      </div>
                  </div>
                  <div class="form-group">
                      <div class="input-group mb-3">
                          <input type="text" class="form-control" [(ngModel)]="canvas.size.height" (keyup)="changeSize()">
                          <div class="input-group-append">
                              <span class="input-group-text">
                                  Height
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>-->
            <!--<div class="card">
                <div class="card-header">Designing Mode</div>
                <div class="card-body">
                    <div class="input-group">

                        <div class="input-group-append">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" checked id="customSwitch1"
                                    (click)="drawMode()">
                                <label class="custom-control-label" for="customSwitch1">Enable / Disable</label>
                            </div>
                            <span class="input-group-text">

                             <button id="add-text" data-toggle="tooltip" data-placement="bottom" title="Drawing Mode" class="btn btn-primary" (click)="drawMode()">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </span> 
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="card">
                <div class="card-header">Add text</div>
                <div class="card-body">
                    <div class="input-group">
                        <input type="text" class="form-control" [(ngModel)]="canvas.textString">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <button id="add-text" data-toggle="tooltip" data-placement="bottom" title="Add text"
                                    class="btn btn-primary" (click)="addText()">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="card">
                <div class="card-header">Options</div>
                <div class="card-body text-center">
                    <div class="btn-group" role="group" aria-label="...">
                        <button data-toggle="tooltip" data-placement="bottom" title="Delete element" type="button"
                            class="btn btn-outline-danger" (click)="removeSelected();">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        <button data-toggle="tooltip" data-placement="bottom" title="Send to back" type="button"
                            class="btn btn-outline-primary" [disabled]="!canvas.selected" (click)="sendToBack();">
                            <i class="fa fa-level-down" aria-hidden="true"></i>
                        </button>
                        <button data-toggle="tooltip" data-placement="bottom" title="Send to front" type="button"
                            class="btn btn-outline-primary" [disabled]="!canvas.selected" (click)="bringToFront();">
                            <i class="fa fa-level-up" aria-hidden="true"></i>
                        </button>
                        <!--<button data-toggle="tooltip" data-placement="bottom" title="Clone" type="button"
                            class="btn btn-outline-primary"
                            [disabled]="!canvas.selected || canvas.selected.type == 'group'" (click)="clone();">
                            <i class="fa fa-clone" aria-hidden="true"></i>
                        </button>-->
                        <button data-toggle="tooltip" data-placement="bottom" title="Unselect" type="button"
                            class="btn btn-outline-primary" [disabled]="!canvas.selected" (click)="cleanSelect()">
                            <i class="fa fa-remove" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <br />
            <div class="card">
                <div class="card-header">Add figure</div>
                <div class="card-body text-center max-height">
                    <div class="btn-group btn-group-vertical" role="group" aria-label="...">
                        <button type="button" class="btn btn-primary" (click)="addFigure('hexa-b');">Formal (past)</button>                        
                        <button type="button" class="btn btn-primary" (click)="addFigure('hexa-a');">Non-formal (past)</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('hexa-c');">Supported Block</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('hexa-formal');">Formal (future)</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('hexa-non-formal');">Non-formal (future)</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('hexa-goal');">Goal in 5-10 years</button>
                        <!--<button type="button" class="btn btn-primary" (click)="addFigure('rectangle');">Formal</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('square');">Non-formal
                            (Past)</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('triangle');">Triangle</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('circle');">Circle</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('hexa');">Hexa</button>-->
                    </div>
                </div>
            </div>
            <br />
            <div class="card">
                <div class="card-header">Add Badges</div>
                <div class="card-body max-height">
                    <ng-container *ngFor="let badge of badges">
                    <img id="{{ badge.slug }}" class="images-item" alt="Badge" (click)='addBadgeOnCanvas($event, badge.slug)' src="{{ badge.image }}">
                    </ng-container>
                </div>
            </div>
            <br />
        </div>
        <div class="col-xl-6">
            <angular-editor-fabric-js #canvas></angular-editor-fabric-js>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <br />
        </div>
    </div>
    <br>
    <br>
</div>