import { ReadVarExpr } from '@angular/compiler';
import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { io } from 'socket.io-client';
import { fabric } from 'fabric';
import { emit } from 'process';

@Component({
  selector: 'angular-editor-fabric-js',
  templateUrl: './angular-editor-fabric-js.component.html',
  styleUrls: ['./angular-editor-fabric-js.component.css'],
})
export class FabricjsEditorComponent implements AfterViewInit {
  private socket: any
  
  @ViewChild('htmlCanvas') htmlCanvas: ElementRef;

  // for badges
  //badges: any[] = [];

  private canvas: fabric.Canvas;
  public props = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: ''
  };

  public textString: string;
  public url: string | ArrayBuffer = '';
  /*public size: any = {
    width: 500,
    height: 800
  };*/
  public size: any = {
    width: 1024,
    height: 768
  };

  public isLoadedFromJson = false;

  public json: any;
  private globalEditor = false;
  public textEditor = false;
  private imageEditor = false;
  public figureEditor = false;
  public selected: any;
  
  
  private isUpdating: boolean = false;

  ngAfterViewInit(): void {
    // socket 
    //this.socket = io('http://localhost:3000');  
    //this.socket = io('http://103.134.154.252:3000');  
    //this.socket = io("https://://inxignia.soi.asia", {
    //  path: '/lifestory-collab/socket.io/',
    //  transports: ['websocket','polling'],
    //});
    //this.socket = io('http://localhost:3000',{
    this.socket = io('http://103.134.154.252:3000',{
       transports: ['websocket'],
    });

    // setup front side canvas
    this.canvas = new fabric.Canvas(this.htmlCanvas.nativeElement, {
      preserveObjectStacking: false,
      hoverCursor: 'pointer',
      selection: true,
      selectionBorderColor: 'blue',
      //isDrawingMode: true
      isDrawingMode: false
    });

    function emitEvent() {
      let aux = this.canvas;
      let json = aux.toJSON();
      let data = {
        w: this.size.width,
        h: this.size.height,
        data: json
      };
      this.socket.emit('canvas-data', data);

    }

    let updateTimeout;

    this.canvas.on({
      'object:added': (e) => {
        const selectedObject = e.target;
        selectedObject.bringToFront();
        
        const canvasData = this.canvas.toJSON();
        if (!this.isUpdating) {
          //this.socket.emit('canvas-data', e.target.toObject());
          this.socket.emit('canvas-data', canvasData);
        }
        //console.log(this.canvas.toJSON());
  
      },
      'object:moving': (e) => { 
        const canvasData = this.canvas.toJSON();
        if (!this.isUpdating) {
          this.socket.emit('canvas-data', canvasData);
        }
        //console.log(this.canvas.toJSON());
  
      },
      'object:modified': (e) => { 
        console.log("start object modified");
        clearTimeout(updateTimeout);

        updateTimeout = setTimeout(() => {
          const selectedObject = e.target;
          selectedObject.bringToFront();

          const canvasData = this.canvas.toJSON();
          if (!this.isUpdating) {
            this.socket.emit('canvas-data', canvasData);
          }
        }, 10000);

        /*
        const selectedObject = e.target;
        selectedObject.bringToFront();

        const canvasData = this.canvas.toJSON();
        if (!this.isUpdating) {
          this.socket.emit('canvas-data', canvasData);
        }*/
        

      },
      'path:created': (e) => {
        const selectedObject = e.target;
        selectedObject.bringToFront();

        const canvasData = this.canvas.toJSON();
        if (!this.isUpdating) {
          this.socket.emit('canvas-data', canvasData);
        }
      },      
      'object:selected': (e) => {

        const selectedObject = e.target;
        this.selected = selectedObject;
        selectedObject.hasRotatingPoint = true;
        selectedObject.transparentCorners = false;
        selectedObject.cornerColor = 'rgba(255, 87, 34, 0.7)';

        this.resetPanels();

        if (selectedObject.type !== 'group' && selectedObject) {

          this.getId();
          this.getOpacity();

          switch (selectedObject.type) {
            case 'rect':
            case 'circle':
            case 'triangle':
              this.figureEditor = true;
              this.getFill();
              break;
            case 'i-text':
              this.textEditor = true;
              this.getLineHeight();
              this.getCharSpacing();
              this.getBold();
              this.getFill();
              this.getTextDecoration();
              this.getTextAlign();
              this.getFontFamily();
              break;
            case 'image':
              break;
          }
          
          selectedObject.bringToFront();
        }
        
        //console.log("selected!");

        const canvasData = this.canvas.toJSON();
        if (!this.isUpdating) {
          //this.socket.emit('canvas-data', e.target.toObject());
          this.socket.emit('canvas-data', canvasData);
        }
      },
      'selection:created': (e) => {
        const selectedObject = e.target;
        //selectedObject.bringToFront();
      },
      'selection:updated': (e) => {
        const selectedObject = e.target;
        //selectedObject.bringToFront();
      },
      'selection:cleared': (e) => {
        this.selected = null;
        this.resetPanels();

        updateTimeout = setTimeout(() => {
          const selectedObject = e.target;
          //selectedObject.bringToFront();

          const canvasData = this.canvas.toJSON();
          if (!this.isUpdating) {
            this.socket.emit('canvas-data', canvasData);
          }
        }, 10000);
      }
    });

    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);

    // get references to the html canvas element & its context
    this.canvas.on('mouse:down', (e) => {
      const canvasElement: any = document.getElementById('canvas');
    });

    
    
    this.socket.on('canvas-data', (data: any) => {
      this.isUpdating = true;
      this.canvas.loadFromJSON(data, () => {
        this.canvas.renderAll();
        this.isUpdating = false;
      })
      
    })
    
    /*this.socket.on('canvas-data', (message: { socketId: string, data: any }) => {
      this.isUpdating = true;
      this.canvas.loadFromJSON(message, () => {
        this.canvas.renderAll();
        this.isUpdating = false;
      })
    });*/

  }


  /*------------------------Block elements------------------------*/

  // Block "Size"

  changeSize() {
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);
  }

  // Block "Add text"

  addText() {
    if (this.textString) {
      const text = new fabric.IText(this.textString, {
        left: 10,
        top: 10,
        fontFamily: 'helvetica',
        angle: 0,
        fill: '#000000',
        scaleX: 0.5,
        scaleY: 0.5,
        fontWeight: '',
        hasRotatingPoint: true
      });

      this.extend(text, this.randomId());
      this.canvas.add(text);
      this.selectItemAfterAdded(text);
      this.textString = '';
    }
  }

  // Block "Add images"
  getImgPolaroid(event: any) {
    const el = event.target;
    fabric.loadSVGFromURL(el.src, (objects, options) => {
      const image = fabric.util.groupSVGElements(objects, options);
      image.set({
        left: 10,
        top: 10,
        angle: 0,
        padding: 10,
        cornerSize: 10,
        hasRotatingPoint: true,
      });
      this.extend(image, this.randomId());
      this.canvas.add(image);
      this.selectItemAfterAdded(image);
    });
  }

  // Block "Upload Image"

  addImageOnCanvas(url) {
    if (url) {
      fabric.Image.fromURL(url, (image) => {
        image.set({
          left: 10,
          top: 10,
          angle: 0,
          padding: 10,
          cornerSize: 10,
          hasRotatingPoint: true
        });
        image.scaleToWidth(200);
        image.scaleToHeight(200);
        this.extend(image, this.randomId());
        this.canvas.add(image);
        this.selectItemAfterAdded(image);
      });
    }
  }

  addBadgeOnCanvas(event: any, url: any) {
    
      const el = event.target;
      fabric.Image.fromURL(el.src, (image) => {
        image.set({
          left: 10,
          top: 10,
          angle: 0,
          padding: 10,
          cornerSize: 10,
          hasRotatingPoint: true
        });
        image.scaleToWidth(100);
        //image.scaleToHeight(50);
        this.extend(image, this.randomId());
        this.canvas.add(image);
        this.selectItemAfterAdded(image);
        // add mouse dbl click
        //this.canvas.on('mouse:dblclick', function(e) {

          //console.log(image.getSrc());
          //console.log('https://inxignia.soi.asia/public/badges/'+url);
          //console.log('ee')
          
          //var a = document.createElement("a");
          //a.setAttribute('target', '_blank');
          //a.target="_blank";
          //a.href = window.location.href;
          //a.href='https://inxignia.soi.asia/public/badges/'+url;
          //a.click();
          //window.location.href='';
          //window.location.href = 'https://inxignia.soi.asia/public/badges/'+url;
        //});
        /*
        'selection:cleared': (e) => {
          this.selected = null;
          this.resetPanels();
        }
        */
        });
      
      
      this.canvas.on({
        'selection:cleared': (e) => {
          //console.log("Selection : "+url);
          //this.canvas.remove(this.canvas.getActiveObject());
          //this.selected = null;
          //this.resetPanels();
          
      },
        'mouse:dblclick': (e) => {
          //console.log(this.canvas.getActiveObject());
          //let activeObjects = this.canvas?.getObjects();
          //console.log(activeObjects);
          this.canvas?.discardActiveObject();
          let activeObjects = this.canvas?.getObjects();
          for (let a = activeObjects.length - 1; a >= 0; a--) {
              if (activeObjects[a]) {
                  activeObjects.splice(a, 1);
              }
          }

          let seleccionar = new fabric.ActiveSelection(activeObjects, {
              canvas: this.canvas
          });
          this.canvas?.setActiveObject(seleccionar);
          this.canvas?.requestRenderAll();
            var a = document.createElement("a");
            a.setAttribute('target', '_blank');
            //a.target="_blank";
           // a.href = window.location.href;
            a.href='https://inxignia.soi.asia/public/badges/'+url;
           a.click();
          }
        
        //'selection:created': (e) => {
          //console.log(this.canvas.getActiveObject());
          //this.canvas.setActiveObject(el);
        //},
        //'mouse:dblclick': (e) => {
        //  console.log(this.canvas.selection);
        //  console.log(this.canvas.getObjects());
          /*var a = document.createElement("a");
          a.setAttribute('target', '_blank');
          a.target="_blank";
          a.href = window.location.href;
          a.href='https://inxignia.soi.asia/public/badges/'+url;
          a.click();*/
        //  this.canvas.getActiveObjects().forEach((obj) => {
            //alert(url);
        //    this.canvas.discardActiveObject().renderAll();
        //   })
           
        //},
    }
    )
  }


  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.url = readerEvent.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeWhite(url) {
    this.url = '';
  }

  // Block "Add figure"

  addFigure(figure) {
    let add: any;
    switch (figure) {
      case 'rectangle':
        add = new fabric.Rect({
          width: 200, height: 100, left: 10, top: 10, angle: 0,
          fill: '#3f51b5'
        });
        break;
      case 'square':
        add = new fabric.Rect({
          width: 100, height: 100, left: 10, top: 10, angle: 0,
          fill: '#4caf50'
        });
        break;
      case 'triangle':
        add = new fabric.Triangle({
          width: 100, height: 100, left: 10, top: 10, fill: '#2196f3'
        });
        break;
      case 'circle':
        add = new fabric.Circle({
          radius: 50, left: 10, top: 10, fill: '#ff5722'
        });
        break;
      case 'hexa-a':
        add = new fabric.Polygon(
          [
             { x: 50, y: 0 },
             { x: 25, y: 43.30},
             { x: -25, y: 43.301 },
             { x: -50, y: 0},
             { x: -25, y: -43.301},
             { x: 25, y: -43.301 },
          ],
          {
             fill: "#CA655A",
             left: 140,
             top: 10,
             scaleX: 1.5,
             scaleY: 1.5
          }
       );
       break;
       case 'hexa-b':
        add = new fabric.Polygon(
          [
             { x: 50, y: 0 },
             { x: 25, y: 43.30},
             { x: -25, y: 43.301 },
             { x: -50, y: 0},
             { x: -25, y: -43.301},
             { x: 25, y: -43.301 },
          ],
          {
             fill: "#555DB9",
             left: 140,
             top: 10,
             scaleX: 1.5,
             scaleY: 1.5
          }
       );
       break;
       case 'hexa-formal':
        add = new fabric.Polygon(
          [
             { x: 50, y: 0 },
             { x: 25, y: 43.30},
             { x: -25, y: 43.301 },
             { x: -50, y: 0},
             { x: -25, y: -43.301},
             { x: 25, y: -43.301 },
          ],
          {
             fill: "#9499CF",
             left: 140,
             top: 10,
             scaleX: 1.5,
             scaleY: 1.5
          }
       );
       break;
       case 'hexa-non-formal':
        add = new fabric.Polygon(
          [
             { x: 50, y: 0 },
             { x: 25, y: 43.30},
             { x: -25, y: 43.301 },
             { x: -50, y: 0},
             { x: -25, y: -43.301},
             { x: 25, y: -43.301 },
          ],
          {
             fill: "#D89C96",
             left: 140,
             top: 10,
             scaleX: 1.5,
             scaleY: 1.5
          }
       );
       break;
       case 'hexa-goal':
        add = new fabric.Polygon(
          [
             { x: 50, y: 0 },
             { x: 25, y: 43.30},
             { x: -25, y: 43.301 },
             { x: -50, y: 0},
             { x: -25, y: -43.301},
             { x: 25, y: -43.301 },
          ],
          {
             fill: "#EBE789",
             left: 140,
             top: 10,
             scaleX: 1.5,
             scaleY: 1.5
          }
       );
       break;
       case 'hexa-c':
        add = new fabric.Polygon(
          [
             { x: 50, y: 0 },
             { x: 25, y: 43.30},
             { x: -25, y: 43.301 },
             { x: -50, y: 0},
             { x: -25, y: -43.301},
             { x: 25, y: -43.301 },
          ],
          {
             fill: "#f4f5f0",
             left: 140,
             top: 10,
             scaleX: 1.5,
             scaleY: 1.5
          }
       );
       break;
    }
    this.extend(add, this.randomId());
    this.canvas.add(add);
    this.selectItemAfterAdded(add);
  }

  changeFigureColor(color) {
    this.canvas.getActiveObject().set("fill", color);
    this.canvas.renderAll();
  };

  /*Canvas*/

  cleanSelect() {
    this.canvas.discardActiveObject().renderAll();
  }

  selectItemAfterAdded(obj) {
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }

  setCanvasFill() {
    if (!this.props.canvasImage) {
      this.canvas.backgroundColor = this.props.canvasFill;
      this.canvas.renderAll();
    }
  }

  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id
        });
      };
    })(obj.toObject);
  }

  setCanvasImage() {
    const self = this;
    if (this.props.canvasImage) {
      this.canvas.setBackgroundColor(new fabric.Pattern({ source: this.props.canvasImage, repeat: 'repeat' }), () => {
        self.props.canvasFill = '';
        self.canvas.renderAll();
      });
    }
  }

  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }

  /*------------------------Global actions for element------------------------*/

  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) { return ''; }

    if (object.getSelectionStyles && object.isEditing) {
      return (object.getSelectionStyles()[styleName] || '');
    } else {
      return (object[styleName] || '');
    }
  }

  setActiveStyle(styleName, value: string | number, object: fabric.IText) {
    object = object || this.canvas.getActiveObject() as fabric.IText;
    if (!object) { return; }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.setSelectionStyles({ underline: true });
        } else {
          object.setSelectionStyles({ underline: false });
        }

        if (value.includes('overline')) {
          object.setSelectionStyles({ overline: true });
        } else {
          object.setSelectionStyles({ overline: false });
        }

        if (value.includes('line-through')) {
          object.setSelectionStyles({ linethrough: true });
        } else {
          object.setSelectionStyles({ linethrough: false });
        }
      }

      object.setSelectionStyles(style);
      object.setCoords();

    } else {
      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.set('underline', true);
        } else {
          object.set('underline', false);
        }

        if (value.includes('overline')) {
          object.set('overline', true);
        } else {
          object.set('overline', false);
        }

        if (value.includes('line-through')) {
          object.set('linethrough', true);
        } else {
          object.set('linethrough', false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }


  getActiveProp(name) {
    const object = this.canvas.getActiveObject();
    if (!object) { return ''; }

    return object[name] || '';
  }

  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) { return; }
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }

  clone() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      let clone;
      switch (activeObject.type) {
        case 'rect':
          clone = new fabric.Rect(activeObject.toObject());
          break;
        case 'circle':
          clone = new fabric.Circle(activeObject.toObject());
          break;
        case 'triangle':
          clone = new fabric.Triangle(activeObject.toObject());
          break;
        case 'hexa-a':
          clone = new fabric.Polygon(activeObject.toObject());
          break;
        case 'hexa-b':
          clone = new fabric.Polygon(activeObject.toObject());
        break;
        case 'hexa-c':
          clone = new fabric.Polygon(activeObject.toObject());
        break;
        case 'hexa-formal':
          clone = new fabric.Polygon(activeObject.toObject());
        break;
        case 'hexa-non-formal':
          clone = new fabric.Polygon(activeObject.toObject());
        break;
        case 'hexa-goal':
          clone = new fabric.Polygon(activeObject.toObject());
        break;
        case 'i-text':
          clone = new fabric.IText('', activeObject.toObject());
          break;
        case 'image':
          clone = fabric.util.object.clone(activeObject);
          break;
      }
      if (clone) {
        clone.set({ left: 10, top: 10 });
        this.canvas.add(clone);
        this.selectItemAfterAdded(clone);
      }
    }
  }

  getId() {
    this.props.id = this.canvas.getActiveObject().toObject().id;
  }

  setId() {
    const val = this.props.id;
    const complete = this.canvas.getActiveObject().toObject();
    console.log(complete);
    this.canvas.getActiveObject().toObject = () => {
      complete.id = val;
      return complete;
    };
  }

  getOpacity() {
    this.props.opacity = this.getActiveStyle('opacity', null) * 100;
  }

  setOpacity() {
    this.setActiveStyle('opacity', parseInt(this.props.opacity, 10) / 100, null);
  }

  getFill() {
    this.props.fill = this.getActiveStyle('fill', null);
  }

  setFill() {
    this.setActiveStyle('fill', this.props.fill, null);
  }

  getLineHeight() {
    this.props.lineHeight = this.getActiveStyle('lineHeight', null);
  }

  setLineHeight() {
    this.setActiveStyle('lineHeight', parseFloat(this.props.lineHeight), null);
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveStyle('charSpacing', null);
  }

  setCharSpacing() {
    this.setActiveStyle('charSpacing', this.props.charSpacing, null);
  }

  getFontSize() {
    this.props.fontSize = this.getActiveStyle('fontSize', null);
  }

  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.props.fontSize, 10), null);
  }

  getBold() {
    this.props.fontWeight = this.getActiveStyle('fontWeight', null);
  }

  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '', null);
  }

  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle('fontStyle', 'italic', null);
    } else {
      this.setActiveStyle('fontStyle', 'normal', null);
    }
  }

  getTextDecoration() {
    this.props.TextDecoration = this.getActiveStyle('textDecoration', null);
  }

  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, 'g'), '');
    } else {
      iclass += ` ${value}`;
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration, null);
  }

  hasTextDecoration(value) {
    return this.props.TextDecoration.includes(value);
  }

  getTextAlign() {
    this.props.textAlign = this.getActiveProp('textAlign');
  }

  setTextAlign(value) {
    this.props.textAlign = value;
    this.setActiveProp('textAlign', this.props.textAlign);
  }

  getFontFamily() {
    this.props.fontFamily = this.getActiveProp('fontFamily');
  }

  setFontFamily() {
    this.setActiveProp('fontFamily', this.props.fontFamily);
  }

  /*System*/


  removeSelected() {
    const activeObject: any = this.canvas.getActiveObject();
    const activeGroup: any = this.canvas.getActiveObjects();

    if (activeGroup) {
      this.canvas.discardActiveObject();
      const self = this;
      activeGroup.forEach((object) => {
        self.canvas.remove(object);
      });
    } else if (activeObject) {
      this.canvas.remove(activeObject);
    }
  }

  bringToFront() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      activeObject.bringToFront();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.bringToFront();
      });
    }
  }

  sendToBack() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.sendToBack(activeObject);
      activeObject.sendToBack();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.sendToBack();
      });
    }
  }

  confirmClear() {
    if (confirm('Are you sure?')) {
      this.canvas.clear();
    }
  }

  rasterize() {
    const image = new Image();
    image.src = this.canvas.toDataURL({ format: 'png' });
    const w = window.open('');
    w.document.write(image.outerHTML);
    this.downLoadImage();
  }

  downLoadImage() {
    const c = this.canvas.toDataURL({ format: 'png' });
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = '_self';
    downloadLink.download = Date.now() + '.png';
    downloadLink.click();
  }

  rasterizeSVG() {
    const w = window.open('');
    w.document.write(this.canvas.toSVG());
    this.downLoadSVG();
    return 'data:image/svg+xml;utf8,' + encodeURIComponent(this.canvas.toSVG());
  }

  downLoadSVG() {
    const c = 'data:image/svg+xml;utf8,' + encodeURIComponent(this.canvas.toSVG());
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = '_self';
    downloadLink.download = Date.now() + '.svg';
    downloadLink.click();
  }

  saveCanvasToJSON() {
    const json = JSON.stringify(this.canvas);
    localStorage.setItem('Lifestory', json);
    console.log('json');
    console.log(json);
    
    // add new function for download json
    const c = 'data:application/json;utf8,' + encodeURIComponent(json);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = '_self';
    downloadLink.download = Date.now() + '.json';
    downloadLink.click();
  }

  loadCanvasFromJSON() {
    const CANVAS = localStorage.getItem('Lifestory');
    console.log('CANVAS');
    console.log(CANVAS);

    // and load everything from the same json
    this.canvas.loadFromJSON(CANVAS, () => {
      console.log('CANVAS untar');
      console.log(CANVAS);

      // making sure to render canvas at the end
      this.canvas.renderAll();

      // and checking if object's "name" is preserved
      console.log('this.canvas.item(0).name');
      console.log(this.canvas);
    });

  }

  loadCanvasFromJSONFile() {
    //localStorage.clear();
    const CANVAS = localStorage.getItem('Lifestory');  
    
    let getFiles = (<HTMLInputElement>document.getElementById('selectFiles')).files;
    //console.log(getFiles);
    
    var fileReader = new FileReader();
    fileReader.onload = function(e) {
      //console.log(e);
      var result = JSON.parse(e.target.result as string);
      //var formatted = JSON.stringify(result, null, 2);
      console.log("Result : " + result);
      var formatted = JSON.stringify(result);
      //console.log(formatted);
      localStorage.setItem('Lifestory', formatted);
      //console.log(getFiles.item(0));
    }
    fileReader.readAsText(getFiles.item(0));
    this.canvas.loadFromJSON(CANVAS, () => {
      this.canvas.renderAll();
    });
}

  rasterizeJSON() {
    this.json = JSON.stringify(this.canvas, null, 2);
  }

  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
  }

  drawingMode() {
    this.canvas.isDrawingMode = !this.canvas.isDrawingMode;
  }

}
