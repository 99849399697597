import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fabric } from 'fabric';

interface Badge {
  id: number;
  name: string;
  image: string;
  slug: string;

}

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  private apiUrl = 'https://inxignia-admin.soi.asia/v1/issuer/lifestory';
  //private apiUrl = 'http://localhost:8080/v1/issuer/lifestory';

  constructor(private http: HttpClient) { }

  getBadges(): Observable<Badge[]> {
    return this.http.get<Badge[]>(this.apiUrl);
  }
}

export class FabricService {
  constructor() {
    this.extendFabricObjects()
  }

  private extendFabricObjects()
  {
    fabric.Image.prototype.toObject = (function (toObject) {
      return function (propertiesToInclude) {
        return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
          linkURL: this.linkURL  // Add linkURL property to the serialized object
        });
      };
    })(fabric.Image.prototype.toObject);
  }


}